import React from "react";
import { navigate } from "gatsby"
import SEO from "../components/seo"
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import plusLogo from "../../static/logo-plus-550x120.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const HelpContent = (props) => {
    const totalUnread = useSelector(state => state.totalUnread);

    return (
       <div className="pageWrapper page textPage">
            <SEO 
                title={totalUnread == 0 ? props.intl.formatMessage({ id: 'help' }) : totalUnread + " " + props.intl.formatMessage({ id: 'dM' })}
                description={props.intl.formatMessage({ id: 'helpSeoDesc' })}
                pagePath="/help"
            />

            <h1><FormattedMessage id="help" /></h1>
            <div className="dividerHr">
                <span className="dividerSpan"></span>
            </div>
            <br />

            <div className="aboutPage">
                <Row>
                    <Col>
                    <h5>Kategorier</h5>
                    <p>
                        Kategorierna <strong>Gillas</strong>, <strong>Diskuteras</strong> och <strong>Senaste</strong> kan väljas från menyn.
                        De visar nyheter och inlägg från de senaste dagarna sorterat efter kategorin. Skrolla bara nedåt för att se ännu mer.
                    </p>
                    </Col>
                    <Col>
                    <h5>Partier</h5>
                    <p>
                        Inlägg kan taggas med partier när de skapas för att tydliggöra om det rör något politiskt parti.
                        Om du vill söka inlägg för specifika partier kan du göra det genom att välja <a onClick={ () => navigate('/parties') } href="#">partier här</a>.
                    </p>
                    </Col>
                </Row>

                <br />

                <Row>
                    <Col>
                        <h5>Skapa</h5>
                        <p>
                            Klicka på <strong>Skapa</strong> för att skapa ett nytt inlägg. Du kan antingen skriva all text själv, eller välja <strong>Länk</strong>. Då hämtas rubrik, text och bild automatiskt från länken, smart va?
                        </p>
                    </Col>
                    <Col>
                        <h5>Total</h5>
                        <p>
                            Alla användare har <strong>Total</strong>-poäng. Poängen visar hur aktiv och uppskattad användaren är, upp- och nedröster på inlägg och kommenterar påverkar poängen direkt.
                        </p>
                        <p>
                            Om du inte vill visa <strong>Total</strong> i din profil kan du stänga av det under Konto.
                        </p>
                    </Col>
                </Row>

                <br />

                <Row>
                    <Col>
                        <h5><FormattedMessage id="wantToBlockUser" /></h5>
                        <p>
                            Om du inte vill se vad en användare skriver längre klickar du på hans eller hennes namn och väljer <strong><FormattedMessage id="block" /></strong>.
                        </p>
                    </Col>
                    <Col>
                        <h5><FormattedMessage id="removeAccount" /></h5>
                        <p>
                            <FormattedMessage id="ifYouWantToRemove" />
                        </p>
                    </Col>
                </Row>
 
                <br />

                <p>Om du märker att någon beter sig illa, eller av andra anledningar vill komma i kontakt med oss, 
                kan du <a href="mailto:support@vibbli.se"><FormattedMessage id="contactUsHere" /></a>.</p>
            </div>


        </div>
    )
}

export default injectIntl(HelpContent);
